<template>
  <div>
    <v-row justify="center"
      ><v-col md="6">
        <div v-if="postObject" elevation="0" class="mx-2">
          <v-img
            v-if="postObject.image_file"
            :src="postObject.image_file['document']"
            contain
            height="300px"
          ></v-img>
          <div class="mt-2">
            <h3 class="mx-2">
              {{ postObject.title }}
            </h3>

            <v-list-item class="px-0 py-0">
              <v-list-item-avatar class="mr-0 my-0">
                <v-icon>mdi-clock</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-wrap">
                  {{ moment(postObject.start_datetime).format("lll") }} -
                  {{
                    moment(postObject.end_datetime).format("lll")
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 py-0">
              <v-list-item-avatar class="mr-0 my-0">
                <v-icon>mdi-view-list</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-wrap"
                v-html="TextHelper.linkify(postObject.description)"
                >
                </v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 py-0">
              <v-list-item-avatar class="mr-0 my-0">
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-wrap">
                  {{ postObject.venue }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <p class="caption grey--text mx-4">
              Posted on {{ moment(postObject.created).format("lll") }}
            </p>
          </div>
        </div>
      </v-col></v-row
    >

    <intract-create-edit-sheet
      persistent
      v-if="currentUser.is_admin"
      title="Edit Event"
      :visible="postEditSheet.visible"
      @close="
        postEditSheet.visible = false;
        postEditSheet.editId = null;
      "
      :fields="formFields"
      enable-media
      :endpoint="endpoints.eventsViewSet"
      :edit-id="postEditSheet.editId"
      :data-object="postEditSheet.obj"
      :edit-success-message="postEditSheet.editSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (postEditSheet.obj = obj)"
    />

    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this event?"
      description="This action cannot be undone"
      @successCallback="deletePost"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
  </div>
</template>

<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import moment from "moment";
import EventBus from "@utils/event_bus";
import { mapActions } from "vuex";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";

export default {
  name: "ViewEventPost",
  mixins: [Mixins.essentials],
  components: {
    IntractCreateEditSheet,
  },
  data() {
    return {
      TextHelper,
      moment: moment,
      postObject: null,
      showDeleteDialog: false,
      postEditSheet: {
        visible: false,
        editSuccessMessage: "Event edited successfully!",
        editId: null,
        obj: {
          uploader: null,
          institution: null,
          created_by: null,
        },
      },
    };
  },
  computed: {
    eventsPostsEndpoint() {
      return this.endpoints.eventsViewSet;
    },
    canManage() {
      return this.postObject && this.currentUser.is_admin;
    },
    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 50,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        venue: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Venue",
          required: false,
          md: 12,
          max: 50,
        },
        start_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          min: new Date(),
          label: "Start Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              !this.postEditSheet.obj.end_datetime ||
              moment(this.postEditSheet.obj.end_datetime).isAfter(
                moment(this.postEditSheet.obj.start_datetime)
              ) ||
              "Start Datetime should be before End Datetime",

            (v) =>
              !v ||
              moment(this.postEditSheet.obj.start_datetime).isAfter(moment()) ||
              "Start Datetime cannot be before the current time.",
          ],
        },
        end_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          hide: this.postEditSheet.obj.start_datetime == null,
          min: new Date(this.postEditSheet.obj.start_datetime),
          label: "End Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.postEditSheet.obj.end_datetime).isAfter(
                moment(this.postEditSheet.obj.start_datetime)
              ) ||
              "End Datetime should be after Start Datetime",
          ],
        },
        image_file: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: false,
          label: "Image (Optional)",
          helper: "JPG, PNG, GIF, etc",
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    async deletePost() {
      this.showDeleteDialog = false;
      var url = this.endpoints.eventsViewSet + this.postObject.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Event successfully deleted",
          type: "success",
        });
        EventBus.$emit("event__post_deleted", this.$route.params.postId);
        this.$router.back();
      }
    },

    async getEventsPost() {
      var url = this.endpoints.eventsViewSet + this.$route.params.postId + "/";
      this.postObject = await this.api.call(this.essentials, url);
      EventBus.$emit("event__post_updated", this.postObject);
    },
    async onRefresh() {
      this.getEventsPost();
    },
    async setAppBar() {
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          ...(moment(this.postObject.end_datetime).isAfter(moment())
            ? [
                {
                  id: 1,
                  title: "Edit",
                  icon: "mdi-pencil",
                  onClick: () => {
                    this.postEditSheet.editId = Number(
                      this.$route.params.postId
                    );
                    this.postEditSheet.visible = true;
                  },
                },
              ]
            : []),
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },
  },
  async created() {
    this.postEditSheet.obj.uploader = this.currentUser.id;
    this.postEditSheet.obj.institution = this.currentInstitution.id;
    this.postEditSheet.obj.created_by = this.currentUser.id;
    await this.getEventsPost();
    if (this.canManage) this.setAppBar();
  },
  beforeDestroy() {
    this.resetAppBarOptions();
  },
};
</script>